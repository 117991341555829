<template>
  <div style="background-color: #f5faff">
    <NavBar style="max-height: 50px;" :pageName="pageName"></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 94.5vh !important;" v-if="!isLoading">
        <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10" style="overflow: scroll; height: 95vh; ">
            <h3
              style="
                text-align: left;
                margin-top: 30px;
                font-weight: 800;
                margin-bottom: 10px;
                margin-left: 10px;
                 font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;
              "
            >
              Appointment Details
            </h3>
            <v-card style="width: 80vw">
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; justify-content: space-between">
                  <div align="left" style="padding: 24px 32px 0 32px">
                    <p style="font-weight: bold;">
                      BOOKING ID: {{ appointment_reference_number }}
                    </p>
                  </div>
                  <div style="display: flex; flex-direction: row;padding:15px 10px 0px 0px">
                    <!-- Hiding Join button in admin page because third person joining should not be allowed
                    <v-btn v-if="meeting_link && status == 'ACTIVE'"
                    -->
                    <!-- <v-btn v-show = "false"
                      depressed
                      class="primary"
                      style="color:white;font-weight: 400;margin-bottom:3px;width: 120px; margin-left:5px; margin-right: 5px;"
                      @click="JoinLink()"
                      >
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/join_call.svg" class="video_join_class" style="height: 24px; width: 24px;"
                        />
                       Join
                     </v-btn> -->
                     <!-- <div style="padding:10px 0px ">
                     <v-btn v-if="config_status == 'NeedAssistance' || config_status == 'Unverified' && status == 'ACTIVE'"
                      depressed
                      class = "ResolveButtonClass"
                      @click="ResolveIssue()"
                      >
                       Resolve
                     </v-btn>
                     </div> -->
                     <div v-if="isLinkActive">
                     <v-select
                     :items= "noShowStatus"
                     v-model="appointmentNoShowStatus"
                     v-if="status == 'ACTIVE' || status == 'COMPLETED'"
                     dense
                     item-text="Value"
                     item-value="Key"
                     outlined
                     height="45"
                     label="No show Status"
                     style="width:200px !important;"
                     @input="updateNoShowStatus"
                     >
                     </v-select>
                     </div>
                  </div>
                </div>
                <v-divider />
                <div style="padding: 24px 32px 0 32px;">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    ">
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      <div>
                        <img
                          src="https://img.icons8.com/color/50/000000/user.png"
                        />
                      </div>
                      <div style="display: flex; flex-direction: column">
                        <div style="color: #828282">Patient Name</div>
                        <div style="font-weight: bold" align="left">
                          {{ nameOfPatient }}
                        </div>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-right: 50px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div align="center">
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">Date</div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                               <DateRendered :dateVal="dateOfAppointment"> </DateRendered>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-left: 20px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/clock.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">
                            Time Slot
                          </div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                            <TimeRender :timeSlot="timeSlot"> </TimeRender>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-left: 32px;
                  padding-top: 10px;
                "
                align="left"
              >
                <p style="color: #828282">Description</p>
                <div style="font-weight: normal" align="left">
                  <p>
                    {{ description }}
                  </p>
                </div>
                <br>
                <br>
                <p style="color: #828282">Patient Details</p>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-right: 5%;
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Age:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ age }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Gender:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patient_gender }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/height.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Height:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patient_height }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Weight:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patient_weight }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/alcoholicIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">
                        Alcoholic:
                      </p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patient_alcoholic
                      }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row;">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/smokeIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Smoker:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patient_smoker
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>

            <v-tabs
              v-model="tab"
              background-color="primary"
              style="width: 80vw ; margin-bottom:2px;"
            >
              <v-tab v-for="item in items" :key="item.tab">
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" style="width: 80vw">
              <v-tab-item >
                <v-card style="padding: 32px 24px;">
                  <v-row>
                    <v-col cols="12">
                      <div style="display: flex; flex-direction: row; margin-top: 10px;">
                        <div>Booking ID:</div>
                        <div align="left" style="width: 50%;">
                          <span style="padding-left: 1%; font-weight: 600;">{{
                            appointment_reference_number}}</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="12">
                      <div id="form" style="width: 95%;">
                        <fieldset class="fieldset_class" style="padding: 15px;">
                          <legend align="left" style="font-weight: bold;">
                            Covid Related Symptoms
                          </legend>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="Fever"
                                value="Fever"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="Shortness of breath"
                                value="Shortness of breath"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="Flu like illness(Sneezing, cough)"
                                value="Flu like illness(Sneezing, cough)"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                                value="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="Contact with a person who had travelled to COVID-19 suspected areas"
                                value="Contact with a person who had travelled to COVID-19 suspected areas"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="None of the Above"
                                value="None of the Above"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="Loss of smell"
                                value="No Smell"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="selected"
                                disabled
                                label="Travelling history within 2 weeks"
                                value="Travelling history within 2 weeks"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </fieldset>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    style="
                      width: 95%;
                      margin-top: 10px;
                      margin-left: 10px;
                      padding: 15px;
                    ">
                    <v-col cols="10" sm="5" md="4" align="left">
                      Allergies
                      <v-textarea
                        class="textarea_class"
                        disabled
                        v-model="allergies"
                        solo
                        rows="1"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="4" align="left">
                      Diagnosed With
                      <v-textarea
                        class="textarea_class"
                        disabled
                        v-model="diagnosed_with"
                        solo
                        rows="1"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="4" align="left">
                      Current Taking Medicines
                      <v-textarea
                        class="textarea_class"
                        disabled
                        v-model="current_taking_medicines"
                        solo
                        rows="1"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row style="width: 95%; margin-top: 10px; margin-left: 10px">
                    <v-col cols="6" sm="6" md="6" align="left">
                      Prescription
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="left">
                      <table style="width: 100%;  background-color: #F1F6FF;">
                        <tr align="left" style="padding: 15px">
                          <td style="font-size: 16px; line-height: 20px">
                            Medicine
                          </td>
                          <td style="font-size: 16px; line-height: 20px">
                            Unit(Tablet/Syrup)
                          </td>
                          <td style="font-size: 16px; line-height: 20px">
                            Dosage
                          </td>
                        </tr>
                        <tr
                          style="
                            padding: 15px;
                            font-weight: 300;
                            margin-left: 10px;
                          "
                          v-for="(item, index) in prescription_object"
                          :key="index"
                        >
                          <td>
                            <v-text-field
                              class="textfield_class"
                              disabled
                              solo
                              v-model="item.medicine"
                            />
                          </td>
                          <td>
                            <v-text-field
                              class="textfield_class"
                              solo
                              v-model="item.unit"
                              disabled
                            />
                          </td>
                          <td>
                            <v-text-field
                              class="textfield_class"
                              solo
                              v-model="item.dosage"
                              disabled
                            />
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row style="width: 95%; margin-top: 10px; margin-left: 10px;">
                    <v-col cols="10" sm="5" md="6" align="left">
                      Suggestions (If Any)
                      <v-textarea
                        class="textarea_class"
                        v-model="suggestions"
                        solo
                        disabled
                        rows="1"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="6" align="left">
                      Examinations to be done (If Any)
                      <v-textarea
                        class="textarea_class"
                        v-model="exams"
                        disabled
                        solo
                        rows="1"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row style="width: 95%; margin-top: 10px; margin-left: 10px" >
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                        v-model="need_follow_up"
                        label="Need Follow up appointment"
                        disabled
                        value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                        v-model="referred_to_specialist"
                        disabled
                        label="Referred to a specialist"
                        value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                        v-model="patient_no_show"
                        disabled
                        label="Patient No Show"
                        value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                        v-model="unstable_connectivity"
                        disabled
                        label="Unstable Connectivity"
                        value="true"
                      ></v-checkbox>
                    </v-col>
                  </v-row> -->
                  <v-row style = "margin: 10px 0px;">
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left">
                      Presenting Symptoms
                    </v-col>
                    <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="presenting_symptoms" solo rows=1></v-textarea> -->
                      <p class="variable">{{presenting_symptoms}}</p>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
                      Past medical history
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="medical_history" solo rows=1></v-textarea> -->
                      <p class="variable">{{medical_history}}</p>
                    </v-col>
                    <v-col style="border-style: hidden groove hidden hidden" cols="4" lg="4" sm="4" md="4" xl="4">
                      Past surgical history
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="surgical_history" solo rows=1></v-textarea> -->
                      <p class="variable">{{surgical_history}}</p>
                    </v-col>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4">
                      Family history
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="family_history" solo rows=1></v-textarea> -->
                      <p class="variable">{{family_history}}</p>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
                      Regular taking/ Current medicine
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea> -->
                      <p class="variable">{{current_taking_medicines}}</p>
                    </v-col>
                    <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
                      Known Drug Allergies
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="allergies" solo rows=1></v-textarea> -->
                      <p class="variable">{{allergies}}</p>
                    </v-col>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4">
                      Vaccination History
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="vaccination_history" solo rows=1></v-textarea> -->
                      <p class="variable">{{vaccination_history}}</p>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                      Diagnosis
                    </v-col>
                    <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea> -->
                      <p class="variable">{{diagnosed_with}}</p>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col cols="3" sm="3" md="3" align="left">
                      Treatment
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="left">
                      <table style=" width: 100%; background-color: #F1F6FF;">
                        <tr align="left" style="padding: 15px;">
                          <th style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</th>
                          <th style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</th>
                          <th style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</th>
                          <th style="font-size:16px; line-height: 20px; padding: 5px;">Duration</th>
                        </tr>
                        <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                           <td style="padding:5px;font-weight: 500">{{item.medicine}}</td>
                           <td style="padding:5px;font-weight: 500">{{item.dosage}}</td>
                           <td style="padding:5px;font-weight: 500">{{item.frequency}}</td>
                           <td style="padding:5px;font-weight: 500">{{item.duration}}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                      Suggestions
                    </v-col>
                    <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
                      <p class="variable">{{suggestions}}</p>
                    </v-col>
                  </v-row>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                      Investigations needed
                    </v-col>
                    <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
                      <p class="variable">{{investigations_needed}}</p>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                      To Come after
                    </v-col>
                    <v-col cols="3" lg="3" sm="3" md="3" xl="3">
                      <!-- <v-select disabled background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/> -->
                      <p class="variable">{{come_after}}</p>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row  style = "margin: 10px 0px;">
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                      Refer To
                    </v-col>
                    <v-col cols="3" lg="3" sm="3" md="3" xl="3">
                      <!-- <v-select disabled background-color="#F1F6FF" solo v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/> -->
                      <p class="variable">{{selected_specialization.specialization_name}}</p>
                    </v-col>
                    <v-col cols="3" lg="3" sm="3" md="3" xl="3" v-if="selected_specialization.specialization_name == 'Others'">
                      <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="others" solo rows=1></v-textarea> -->
                      <p class="variable">{{others}}</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <div
                    width="99%"
                    height="60vh"
                    style="
                      margin-right: 2%;
                      border-radius: 12px;
                    "
                  >
                    <div
                      style="
                        display: flex;
                        flex-dirextion: row;
                        flex-wrap: wrap;
                      "
                    >
                      <v-card
                        width="20vw"
                        style="border-radius: 12px; margin: 2%"
                        v-for="prescription in prescriptionDetails"
                        :key="prescription.appointment_reference_number"
                      >
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: left;
                            margin-top: 15px !important;
                          "
                        >
                          <div>

                            <h4
                              align="left"
                              style="padding-top: 10px; margin-left: 20px"
                              v-if="prescription.diagnosed_with != ''"
                            >
                             Diagnosed with: {{ prescription.diagnosed_with }}
                            </h4>

                            <h4
                              align="left"
                              style="padding-top: 10px; margin-left: 20px"
                              v-else
                            >
                             Diagnosed with: NA
                            </h4>
                          </div>

                          <div
                            style="
                              display: flex;
                              flex-direction: row;
                              justify-content: space-between;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                flex-direction: column;
                                padding: 2px;
                                margin-top: 5%;
                                margin-left: 5%;
                              "
                            >
                              <div>
                                <h6
                                  style="
                                    margin-top: 20%;
                                    color: #828282 !important;
                                  "
                                  align="left"
                                >
                                  Doctor Name
                                </h6>
                              </div>
                              <div>
                                <p
                                  style="margin-top: 2px; font-weight: 700;font-size: 0.8em"
                                  align="left"
                                >
                                  {{ prescription.doctor_name }}
                                </p>
                              </div>
                            </div>

                            <div>
                              <div
                                style="
                                  display: flex;
                                  flex-direction: row;
                                  margin-top: 35%;
                                  margin-right: 10px;
                                "
                              >
                                <div>
                                  <img
                                    style="width: 25px; height: 25px"
                                    src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                                  />
                                </div>
                                <div style="margin-top: 3px; margin-left: 5px; font-size: 0.8em">
                                  <strong>
                                     <DateRendered :dateVal=" prescription.date_of_appointment"> </DateRendered>
                                    </strong>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style="margin-top: 10px; margin-bottom: 10px">
                            <v-btn
                              color="primary"
                              width="85%"
                              @click="viewPrescription(prescription._id)"
                              style="border-radius: 8px; bottom: 10px;background-color: #1467BF;"
                            >
                              View Prescription
                            </v-btn>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
      <div v-else style="margin-top: 10%;">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import DateRendered from "../Customer/dateRender.vue"
import TimeRender from "../Customer/TimeRender.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "CustomerHome",
  data() {
    return {
      //viewAppointments
      book_id: "",
      patientDetails:"",
      patient_gender : "",
      patient_height : "",
      patient_weight : "",
      patient_alcoholic : "",
      patient_smoker : "",
      appointment_reference_number: "",
      pageName: "viewParticularAppointment",
      currentUser: "",
      currentUserName: "",
      nameOfPatient: "",
      timeSlot: "",
      dateOfAppointment: "",
      description: "",
      reason: "",
      patient_id: "",
      age: "",
      tab: null,
      items: [
        { tab: "Prescription", content: "Tab 1 Content" },
        { tab: "Prescription History", content: "Tab 2 Content" },
      ],
      prescriptionId: "",
      customer_gender:"",
      particularPrescriptionDetails: "",
      unstable_connectivity: false,
      patient_no_show: false,
      referred_to_specialist: false,
      need_follow_up: false,
      exams: "",
      suggestions: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      current_taking_medicines: "",
      diagnosed_with: "",
      allergies: "",
      selected: [],
      date_of_appointment: "",
      prescriptionDetails: "",
      break_here_for_newtemplate_variable: "",
      presenting_symptoms: "",
      medical_history: "",
      surgical_history: "",
      family_history: "",
      current_taking_medicines: "",
      allergies: "",
      vaccination_history: "",
      diagnosed_with: "",
      investigations_needed: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      follow_up_list: ["One Week", "Two week", "One Month", "Two Month","Three Month","Not Required"],
      come_after: "",
      next_followup_date: "",
      others: "",
      specialization_list: [],
      selected_specialization: "",
      isLoading: true,
      meeting_link: "",
      status: "",
      config_status: "",
      booked_by_id: "",
      noShowStatus:[{"Key":"DOCTOR", "Value":"Doctor No Show"},{"Key":"PATIENT", "Value":"Patient No Show"},{"Key":"BOTH", "Value":"Both No Show"},{"Key":"NONE", "Value":"-"}],
      appointmentNoShowStatus:"",
      booking_closing_epoch_time:"",
      isLinkActive:false,
    };
  },
  mounted() {
    this.pageName = "AdminHome";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var params = this.$route.params;
      var booking_id = params['booking_id'];
      this.book_id = booking_id;
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        bookingId : this.book_id,
        page : 1,
        limit : 8
      };
      axios_auth_instance_admin.post("/adminViewParticularAppointmentPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.admin_data.admin_name;
        var current_time = new Date(Date()).toLocaleString("en-us",{timeZone: "Asia/Yangon"});
        var appointment_object = successResponse.data.appointment_data;
        // console.log("APpointment Object ",appointment_object )
        // console.log("current epoch time ",current_time)
        if(Object.keys(appointment_object).length > 0)
        {
          this.appointment_reference_number = appointment_object.appointment_reference_number;
          this.meeting_link = appointment_object.meeting_link;
          this.status = appointment_object.status;
          this.nameOfPatient = appointment_object.booked_for_name;
          this.reason = appointment_object.reason;
          this.description = appointment_object.description;
          this.timeSlot = appointment_object.time_slot;
          this.dateOfAppointment = appointment_object.date_of_appointment;
          this.patient_id = appointment_object.booked_for_id;
          this.booked_by_id = appointment_object.booked_by_id;
          //this.appointmentNoShowStatus = appointment_object.no_show;
          if(appointment_object.no_show == "-" || appointment_object.no_show == "NA" || appointment_object.no_show == " " || appointment_object.no_show == "NONE" )
          {
            this.appointmentNoShowStatus = "NONE"
          }
          else{
              this.appointmentNoShowStatus = appointment_object.no_show;
          }
          this.booking_closing_epoch_time = appointment_object.booking_closing_epoch_time;
          this.isLinkActive = (this.booking_closing_epoch_time >= this.booking_closing_epoch_time +(48*60*60*1000)  ) ? false : true
        }
        this.patientDetails = successResponse.data.patient_data;
        this.config_status = successResponse.data.config_status;
        if(successResponse.data.dependent_flag){
          this.age = this.getAge(this.patientDetails.dependent_dob);
          this.patient_gender = this.patientDetails.dependent_gender;
          this.patient_height = this.patientDetails.dependent_height;
          this.patient_weight = this.patientDetails.dependent_weight;
          this.patient_alcoholic = this.patientDetails.dependent_alcoholic;
          this.patient_smoker = this.patientDetails.dependent_smoker;
        }
        else{
          this.age = this.getAge(this.patientDetails.customer_dob);
          this.patient_gender = this.patientDetails.customer_gender;
          this.patient_height = this.patientDetails.customer_height;
          this.patient_weight = this.patientDetails.customer_weight;
          this.patient_alcoholic = this.patientDetails.alcoholic;
          this.patient_smoker = this.patientDetails.smoker;
        }
        // this.age = this.getAge(this.patientDetails.customer_dob);

        this.prescriptionDetails = successResponse.data.prescription_data;
        if (successResponse.data.appointment_prescription_data) {
          var presc_dets = successResponse.data.appointment_prescription_data[0];
          this.selected = presc_dets['covid_related_symptoms'];
          this.allergies = presc_dets['allergies'];
          this.diagnosed_with = presc_dets['diagnosed_with'];
          this.current_taking_medicines = presc_dets['current_taking_medicines'];
          this.suggestions = presc_dets['suggestion'];
          if(presc_dets['need_follow_up'] == true)
            this.need_follow_up = "true"
          this.exams = presc_dets['examination_to_be_done'];
          if(presc_dets['patient_no_show'] == true)
            this.patient_no_show = presc_dets['patient_no_show'];
          if(presc_dets['referred_to_specialist'] == true)
            this.referred_to_specialist = "true";
          if(presc_dets['unstable_connectivity'] == true)
          {
            this.unstable_connectivity = "true";
          }
          if(presc_dets['prescription'].length > 0)
            this.prescription_object = presc_dets['prescription'];
          this.presenting_symptoms = presc_dets.presentSymptoms;
          this.medical_history = presc_dets.medicalHistory;
          this.surgical_history = presc_dets.surgicalHistory;
          this.family_history = presc_dets.familyHistory;
          this.vaccination_history = presc_dets.vaccinationHistory;
          this.investigations_needed = presc_dets.investigationNeeded;
          this.come_after = presc_dets.next_followup_date;
          axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", data)
          .then((readAllSpecializationsResponse) => {
            var spec_list = readAllSpecializationsResponse.data.data;
            this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
            spec_list.forEach((x) =>{
              this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
            });
            let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
            if(obj)
            {
              this.selected_specialization = obj;
            }
            else if(presc_dets)
            {
              if(presc_dets.referredSpecialistName == 'Not Required')
              {
                this.selected_specialization = {'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"}
              }
              else
              {
                this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
                this.others = presc_dets.referredSpecialistName;
              }
            }
          })
          .catch((readAllSpecializationsError) =>{
          });
        }
        this.isLoading = false;
      })
      .catch((errorResponse) => {
        this.isLoading = false;
        handleError(errorResponse, this.$router, 'adminViewParticularAppointmentPage', 'AdminLogin', 'token')
      });
    }
  },
  components: {
    NavBar,
    DateRendered,
    TimeRender,
    SideBar
  },
  methods: {
    JoinLink() {
      window.open(this.meeting_link);
    },
    ResolveIssue() {
      this.$router.push({
        path: "/admin/ViewPatientDevices/"+this.booked_by_id
      });
    },
    viewPrescription(prescriptionID) {
      this.prescriptionId = prescriptionID;
      let routeData = this.$router.push({
        path: "/admin/AdminViewPrescription/" + prescriptionID,
      });
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    updateNoShowStatus(){
      var noShowStatusBody = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        bookingId: this.book_id,
        noShowStatus:this.appointmentNoShowStatus
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/updateNoShowStatusInAppointment",noShowStatusBody)
        .then((updateNoShowStatusInAppointmentResponse) => {
        })
        .catch((updateNoShowStatusInAppointmentError) => {
          console.log(updateNoShowStatusInAppointmentError);
          if(updateNoShowStatusInAppointmentError.response.status == 403)
          {
            window.alert("Appointment is not completed yet");
          }
          });
    }
  },
};
</script>

<style scoped>
.theme--light.v-divider
  {
    border: 1px solid #C4C4C4 !important;
    width: 95%;
    margin: auto;
  }
  .fieldset_class
  {
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: none;
  }
  .textfield_class {
    box-sizing: border-box;
    border-radius: 8px;
  }
  #facebook{
  display: none;
}
.video_join_class
{
  background-color: #1976d2;
  border-radius: 4px;
  padding: 4px;
}
.ResolveButtonClass {
  background-color: white !important;
  border:solid 1px #1467BF !important;
  margin-bottom:3px;
  width: 120px;
  margin-left:5px;
  margin-right: 5px;
  font-weight: 500;
  line-height: 22px;
  color: #1467BF !important;
}

</style>
<style>
.v-text-field--outlined>.v-input__control>.v-input__slot{
  min-height: 20px !important;
}
.v-text-field__details{
min-height: 0px !important;
}
.v-messages{
  min-height: 0px !important;
}
</style>